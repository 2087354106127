import { ISaveDraft, IAnswers, IUpdateFormAnswers } from './../../models/ISaveDraft';
import { IFormFooter, IFormHeader, IRenderForm } from "../form-state";
import { createAction, props } from '@ngrx/store';
import { IFHIRQuestionnaire } from '../../models/FHIR-Questionnaire';

// LOAD
export const LoadQuestionnaireCompleteActionShared = createAction('[Render-form] Load Questionnaire Complete', props<{ questionnaire: IFHIRQuestionnaire }>());
export const LoadQuestionnaireActionShared = createAction('[Render-form] Load Questionnaire', props<{ questionnaire: ILoadQuestionnaire }>());
export const loadQuestionnaireFailure = createAction('[Render-form] Load Questionnaire Failed', props<({error: any})>());

export const LoadQuestionnaireByFormIdCompleteActionShared = createAction('[Render-form] Load Questionnaire By FormId Complete', props<{ id: string }>());

export const LoadFormHeader = createAction('[Render-form] Load form-header to form', props<{header: IFormHeader}>());
export const LoadFormFooter = createAction('[Render-form] Load form-footer to form', props<{footer: IFormFooter}>());
// RESET
export const ResetQuestionnaireShared = createAction('[Render-form] Reset');
// SAVE
export const SaveDraft = createAction('[Render-form] Save Draft', props<{ draft: ISaveDraft }>());
export const SaveDraftComplete = createAction('[Render-form] Save Draft Complete', props<{ draft: ISaveDraft }>());
export const SaveDraftFailure = createAction('[Render-form] Save Draft Failure', props<{ error: string}>());
export const SavePreview = createAction('[Render-form] Save Preview', props<{ preview: ISaveDraft }>());
export const SavePreviewComplete = createAction('[Render-form] Save Preview Complete', props<{ preview: IAnswers[] }>());
export const SaveResults = createAction('[Render-form] Save Results', props<{ result: ISaveDraft }>());
export const SaveResultsComplete = createAction('[Render-form] Save Results Complete', props<{ result: ISaveDraft }>());
export const SaveResultFailure = createAction('[Render-form] Save Result Failure', props<{ error: any }>());
export const CloseFormAfterSaving = createAction('[Render-form] Close Form After Save');
export const ForceSaveDraft = createAction('[Render-form] Set Force Save Draft');
// UPDATE
export const UpdateForm = createAction('[Render-form] Update Form', props<{ form: IUpdateFormAnswers }>());
export const UpdateFormComplete = createAction('[Render-form] Update Form Complete', props<{ form: IUpdateFormAnswers }>());
// GET
export const GetDraftResults = createAction('[Render-form] Get Draft Results', props<{ result: ILoadQuestionnaire }>());
export const GetDraftResultsComplete = createAction('[Render-form] Get Draft Results Complete', props<{ result: IAnswers[] }>());

export interface ILoadQuestionnaire {
    formId: string;
    bookingId: string;
    formVersionId: number;
    bookedFormId: string;
}
