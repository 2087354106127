import { Action, createReducer, on } from "@ngrx/store";
import * as PatientActions from './patient.actions'
import { PatientState } from "../models/patientState";

const initialState: PatientState = {
    session: null,
    bookingRequest: null,
    activeForm: null,
    language: {
        current: 1053,
        supportedLanguages: [{
            id: '',
            name: 'Svenska/Swedish',
            languageName: 'Svenska',
            code: 1053,
            resource: 'sv-SE',
            country: 'Svenska',
            rtl: false,
            careproviderName: 'Svenska'
        }]
    },
    unauthorized: false
}

const reducer = createReducer<PatientState>(
    initialState,
    on(PatientActions.setSession, (state, action) => {
        return {
            ...state,
            session: action.session
        }
    }),
    on(PatientActions.loginSuccess, (state, action) => {
        return {
            ...state,
            session: action.session,
            unauthorized: false
        }
    }),
    on(PatientActions.loginFailure, (state) => {
        return {
            ...state,
            unauthorized: true
        }
    }),
    on(PatientActions.loadSessionSuccess, (state, action) => {
        return {
            ...state,
            session: action.session,
            language: {
                ...state.language, 
                current: action.session.languageId ? action.session.languageId : state.language.current
            },
            unauthorized: false
        }
    }),
    on(PatientActions.loadBookingRequestSuccess, (state, action) => {
        return {
            ...state,
            bookingRequest: action.bookingRequest
        }
    }),
    on(PatientActions.logout, (state) => {
        return {
            ...initialState,
            language: state.language,
            unauthorized: false
        }
    }),
    on(PatientActions.logoutSso, (state) => {
        return {
            ...initialState,
            language: state.language,
            unauthorized: false
        }
    }),
    on(PatientActions.setActiveForm, (state, action) => {
        return {
            ...state,
            activeForm: action.id
        }
    }),
    on(PatientActions.clearActiveForm, (state) => {
        return {
            ...state,
            activeForm: initialState.activeForm
        }
    }),
    on(PatientActions.setActiveFormDraft, (state) => {
        if(state.activeForm){
            return {
                ...state,
                bookingRequest: {
                    ...state.bookingRequest,
                    forms: state.bookingRequest.forms.map( f => f.id === state.activeForm ? {...f, status: 20} : f),
                },
                activeForm: initialState.activeForm
            }
        } else {
            return {
                ...state
            }
        }
    }),
    on(PatientActions.setActiveFormCompleted, (state) => {
        if(state.activeForm){
            return {
                ...state,
                bookingRequest: {
                    ...state.bookingRequest,
                    forms: state.bookingRequest.forms.map( f => f.id === state.activeForm ? {...f, status: 30} : f),
                },
                activeForm: initialState.activeForm
            }
        } else {
            return {
                ...state
            }
        }
    }),
    on(PatientActions.loadSupportedLanguagesSuccess, (state, action) => {
        return {
            ...state,
            language: {
                ...state.language,
                supportedLanguages: action.supportedLanguages
            }
        }
    }),
    on(PatientActions.loadBookingRequestFailure, (state) => {
        return {
            ...state,
            language: {
                ...state.language,
                supportedLanguages: initialState.language.supportedLanguages
            }
        }
    }),
    on(PatientActions.setCurrentLanguage, (state, action) => {
        return {
            ...state,
            language: {
                ...state.language,
                current: action.language
            }
        }
    }),
    on(PatientActions.logoutSuccess, (state) => {
        return {
            ...state,
            session: initialState.session,
            bookingRequest: initialState.bookingRequest,
            activeForm: initialState.activeForm,
            unauthorized: initialState.unauthorized,
            language: {
                ...state.language,
                current: initialState.language.current
            }
        }
    }),
    on(PatientActions.logoutFailure, (state) => {
        return {
            ...state,
            session: initialState.session,
            bookingRequest: initialState.bookingRequest,
            activeForm: initialState.activeForm,
            unauthorized: initialState.unauthorized,
            language: {
                ...state.language,
                current: initialState.language.current
            }
        }
    })
)

export function patientReducer(state, action: Action) {
    return reducer(state, action);
}