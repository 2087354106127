import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IScope } from 'angular';
import { WINDOW } from '../../../../Shared/app/core/services/window.service';
import { TranslateService } from '@ngx-translate/core';
import * as PatientActions from './state/patient.actions';
import { Store } from '@ngrx/store';
import { DOCUMENT, Location } from '@angular/common';
import { selectCurrentLanguage } from './state/patient.selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PatientState } from './models/patientState';


@Component({
    selector: 'wfp-app',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
      private _onDestroy: Subject<void> = new Subject();

    environment: string;
    router: any;
    private readonly defaultLanguage = 'sv-SE';

    constructor(
        private _route: Router,
        private translateService: TranslateService,
        private store: Store<PatientState>,
        private location: Location,
        @Inject(WINDOW) private window: any,
        @Inject(DOCUMENT) private document: Document,
        @Inject("$rootScope") private _$rootScope: any
    ) {} 
        
    ngOnInit(): void { 
        this.store.select(selectCurrentLanguage).pipe(takeUntil(this._onDestroy)).subscribe( languageInfo => {
            if(languageInfo?.currentLaguage) {
                this.translateService.use(languageInfo.currentLaguage);
    
                this._$rootScope.currentWfLanguage = languageInfo.currentLaguage;
                this._$rootScope.direction = languageInfo.isRtl ? 'rtl' : 'ltr';
    
                this.document.documentElement.dir = languageInfo.isRtl ? 'rtl' : 'ltr';
            }
        });
        

        this.translateService.setDefaultLang(this.defaultLanguage);
        this.translateService.use(this.defaultLanguage);

        this.store.dispatch(PatientActions.setAntiforgery());
        this.router = this._route.url;
        this.environment = this.window.document.wfEnvironment;
        if(!this.location.path().includes('loginType=sso')){
            this.store.dispatch(PatientActions.loadSessionFromServer());
        } 
        this.store.dispatch(PatientActions.loadSupportedLanguagesFromServer());
     }

     ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
     }
}

export interface IEnviromentScope extends IScope {
    wfEnvironment: string;
}
