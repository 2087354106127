export const notTestedForms: any[] = [
    "Diabetes",
    "Hälsodeklaration inför operation (Gyn Anestesi)",   
    "ICS Barn",
    "ICS Vuxen",
    "PROM Bröstcancer", 
];


export const testedForms: any[] = [
    "CAT",   
    "EORTC QLQ-BN20 Symtom vid hjärntumör",
    "Hälsodeklaration inför operation",
    "I-PSS",  
    "Kranio-PROM",
    "Levnadsvanor Hjärta-Kärl",
    "MIA",  
    "MOS-SSS",
    "PROM Allmän",
    "RHI",   
    "SCI",
    "SIAS",
    "SPS",
    "SUPRA CASE-MIX",
    "VAS",
];

export const approvedForms: any[] = [
    "5-15",
    "AS-18",
    "AUDIT",
    "AUDIT-C",
    "ASRS-aktuell symtomnivå",
    "ASRS-screening",
    "ASRS-utredning",
    "ASSIST-Y",
    "BSL-23",
    "CIA",
    "CPRS-minnesstörning",
    "DERS-16",
    "DIP-Q", 
    "DUDIT",
    "DSHI-9r - Självskadebeteende",
    "EDE-Q",
    "EQ-5D-3L (bortsett från direktiv 123 på grund av Copyrightskydd)",
    "EQ-5D-5L (bortsett från direktiv 123 på grund av Copyrightskydd)",
    "GAD-7",
    "GSRS",   
    "HAD",
    "Hemblodtrycksmätning",
    "Hälsoskattning för cancerrehabilitering",
    "KABOSS-S",
    "LILA",
    "LSAS-SR",
    "Levnadsvanor",
    "MADRS-S",
    "MDQ",
    "Mental trötthet (MFS)",
    "Obesity-related Problems scale (OP)",
    "OCI-R",
    "PCL-5 inledande frågor",
    "PCL-5 symtomskattning",
    "PCS Smärta",  
    "PDSS-SR",
    "PHQ-9",
    "P-SEC Biverkningslista, självskattning",
    "P-SEC Biverkningslista, föräldraskattning",
    "PSS-10",
    "PSWQ",
    "RAADS-14",
    "RAND-36",
    "RCADS – Barnversion",
    "RCADS – Föräldraversion",
    "SHAI-14",
    "SDQ Föräldraskattning(4 år-)",
    "SDQ Barn självskattning(11 år-)",
    "SDQ Uppföljning Föräldraskattning(4 år-)",
    "SDQ Uppföljning Barn självskattning(11 år-)",
    "SDS",
    "SNAP-IV",
    "SUPRA-PREM",
    "WHODAS 2.0 - 12 frågor, självadministrerad",
    "WHODAS 2.0 - 36 frågor, självadministrerad",
    "WURS-25",
    "Y-BOCS-markeringslista",
    "Y-BOCS-SR",
    "ÖMPSQ"
]