<div id="logout" class="container">
    <div class="header-logo">
        <div class="wf-logo"></div>
        <div class="region-logo"></div>
    </div>
    <article>
        <div class="centered">
            <h1>Utloggning pågår</h1>
            <p>
                Vänligen vänta, utloggning pågår
                <span class="loader loader-primary">
                    &nbsp;
                </span>
            </p>
        </div>
    </article>
</div>