import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BookedFormState } from "Shared/app/enums/BookedFormState";
import { FormType } from "Shared/app/enums/FormType";
import { PatientLoginType } from "../constants/PatientLoginType";
import { BookingOverviewState } from "../constants/BookingOverviewState";
import { DEBUG, TEST } from "Shared/app/constants/environments.constants";
import { PatientState } from "../models/patientState";

const selectFeatureState = createFeatureSelector<PatientState>('patient');

export const selectSessionStatus = createSelector(selectFeatureState, state => !!state.session);
export const selectIsIneraLogin = createSelector(selectFeatureState, state => state.session?.loginTypeMetadata.patientLoginType === PatientLoginType.Inera);
export const selectLogoutData = createSelector(selectSessionStatus, selectIsIneraLogin, (isLoggedIn, isSsoLogin) => {
    return {
        isLoggedIn,
        isSsoLogin
    }
});
export const selectUnauthorized = createSelector(selectFeatureState, state => state.unauthorized);
export const selectBookingRequestId = createSelector(selectFeatureState, state => state.session?.bookingRequestId);
export const selectPatientName = createSelector(selectFeatureState, state => state.session?.patientName ? state.session?.patientName : state.session?.patientId);

export const selectSessionComponentInfo = createSelector(selectFeatureState, selectBookingRequestId, (state, id) => {
    return {
        activeForm: state.activeForm,
        id
    }
});

export const selectNextForm = createSelector(selectFeatureState, state => state.bookingRequest?.forms?.find( f => f.status < BookedFormState.Completed));

export const selectCurrentLanguageCode = createSelector(selectFeatureState, state => state.language.current);
export const selectCurrentLanguage = createSelector(selectFeatureState, state => {
    return {
        currentLaguage: state.language.supportedLanguages.find( l => l.code === state.language.current)?.resource,
        isRtl: !!state.language.supportedLanguages.find( l => l.code === state.language.current)?.rtl
    }
});

export const selectCareUnit = createSelector(selectFeatureState, state => state.bookingRequest?.careUnit);
export const selectActiveForm = createSelector(selectFeatureState, (state) => {
    return {
        booking: { id: state.bookingRequest?.forms?.find(f => f.id === state.activeForm)?.bookingId },
        bookedForm: state.bookingRequest?.forms?.find(f => f.id === state.activeForm),
    }
});

export const selectIsRtlAndActiveFormDoesNotHaveRtl = createSelector( selectFeatureState, state => {
    return {
        isRtlAndFormHasNotRtl: state.language.supportedLanguages.find( l => l.code === state.language.current)?.rtl && !state.bookingRequest?.forms?.find(f => f.id === state.activeForm)?.languageIds.includes(state.language.current)
    }
});

export const selectDynamicFormData = createSelector(selectFeatureState, selectBookingRequestId,  (state, bookingRequestId ) => {
    return {
        formData: {
            bookedFormId: state.bookingRequest?.forms?.find(f => f.id === state.activeForm)?.id,
            bookingId: state.bookingRequest?.forms?.find(f => f.id === state.activeForm)?.bookingId,
            formId: state.bookingRequest?.forms?.find(f => f.id === state.activeForm)?.formId,
            renderType: state.bookingRequest?.forms?.find(f => f.id === state.activeForm)?.renderType,
            formType: state.bookingRequest?.forms?.find(f => f.id === state.activeForm)?.formType
        },
        bookingRequestId
    }
});

export const selectOverviewForms = createSelector(selectFeatureState, state => {
    return {
        completedForms: state.bookingRequest?.forms?.filter(f => f.status >= BookedFormState.Completed).filter(f => f.formType !== FormType.AnonymousForm),
        nonCompletedForms: state.bookingRequest?.forms?.filter(f => f.status < BookedFormState.Completed),
    }
});

export const selectOverviewFormsLength = createSelector(selectOverviewForms, forms => {
    return {
        completedFormsAmount: forms.completedForms?.length ?? 0,
        nonCompletedFormsAmount: forms.nonCompletedForms?.length ?? 0
    }
});

export const selectFutureBookings = createSelector(selectFeatureState, state => state.bookingRequest?.upcomingSchedules);
export const selectLanguagesForSelector = createSelector(selectFeatureState, selectActiveForm, (state, activeForm )=> {
    return {
        supportedLanguages: state.language.supportedLanguages,
        currentLanguage: state.language.supportedLanguages.find( l => l.code === state.language.current),
        hasSession: !!state.session,
        activeFormName: activeForm.bookedForm?.name,
        activeFormLanguages: activeForm.bookedForm?.languageIds
    }
});

export const selectIsRTL = createSelector(selectFeatureState, state => !!state.language.supportedLanguages.find( l => l.code === state.language.current)?.rtl);
export const selectIsTestEnvironment = createSelector(selectFeatureState, state => state.session?.environment === DEBUG || state.session?.environment === TEST)

export const selectBookingRequestState = createSelector(selectFeatureState, selectNextForm, (state, nextForm) => {
    const anyCompletedForms = state.bookingRequest?.forms?.filter(f => f.status >= BookedFormState.Completed).length > 0;
    const anyNonCompletedForms = state.bookingRequest?.forms?.filter(f => f.status < BookedFormState.Completed).length > 0;
    const isNextFormDraft = nextForm?.status === BookedFormState.Draft;
    const anyUpcoming = state.bookingRequest?.upcomingSchedules.filter(s => !s.deleted).length > 0;
    const onlyAnonymous = state.bookingRequest?.onlyAnonymousForm;
    
    if(!anyCompletedForms && !anyNonCompletedForms) {
        return anyUpcoming ? BookingOverviewState.Upcoming : BookingOverviewState.Cancelled;
    }
    
    if(anyNonCompletedForms && isNextFormDraft) {
        return anyCompletedForms ? BookingOverviewState.InProgressDraft : BookingOverviewState.Draft;
    }
    
    if(!anyCompletedForms && anyNonCompletedForms) {
        return BookingOverviewState.NotStarted;
    }
    
    if(anyCompletedForms && anyNonCompletedForms) {
        return BookingOverviewState.InProgress;
    }
    
    if(anyCompletedForms && !anyNonCompletedForms && anyUpcoming ){
        return BookingOverviewState.TemporaryCompleted;
    }
    
    if(anyCompletedForms && !anyNonCompletedForms && !anyUpcoming ){
        return onlyAnonymous? BookingOverviewState.CompletedAnonymous: BookingOverviewState.Completed;
    }

    return BookingOverviewState.Unknown;
});

export const selectHeaderInfo = createSelector(selectFeatureState, selectBookingRequestState, (state, bookingState) => { 
    return {
        patientName: state.session?.loginTypeMetadata?.metadata?.agentForName ? state.session?.loginTypeMetadata?.metadata?.agentForName : state.session?.patientName ? state.session?.patientName : state.session?.patientId,
        careUnitName: state.bookingRequest?.careUnit.name,
        isActiveForm: !!state.activeForm,
        isPatientLogin: state.session?.formTypeLogin === FormType.Patient,
        isAgentLogin: !!state.session?.loginTypeMetadata?.metadata?.agentId,
        isIneraLogin: state.session?.loginTypeMetadata?.patientLoginType === PatientLoginType.Inera,
        returnUrl: state.session?.loginTypeMetadata?.metadata?.backTo1177,
        logoutButtonGreen: bookingState == BookingOverviewState.Completed || bookingState == BookingOverviewState.CompletedAnonymous || bookingState == BookingOverviewState.TemporaryCompleted
    }
});
export const selectIsPatientLogin = createSelector(selectFeatureState, state => state.session?.formTypeLogin === FormType.Patient);
export const selectIsOnlyAnonymousForms = createSelector(selectFeatureState, state => state.bookingRequest?.onlyAnonymousForm);
export const selectAnyUpcomingBookings = createSelector(selectFeatureState, state => state.bookingRequest?.upcomingSchedules.filter(s => !s.deleted).length > 0);
export const selectSendBackToInera = createSelector(selectFeatureState, state => {
    return {
        shouldReturn: state.session?.loginTypeMetadata.patientLoginType === PatientLoginType.Inera && state.bookingRequest?.forms?.length === 1 && state.bookingRequest.forms[0].formType === FormType.AnonymousForm && state.bookingRequest.forms[0].status === BookedFormState.Completed,
        returnUrl: state.session?.loginTypeMetadata.metadata?.backTo1177
    }
});

export const selectMainOverview = createSelector(selectAnyUpcomingBookings, selectOverviewFormsLength, selectIsPatientLogin, (anyUpcoming, formsLength, isPatientLogin) => {
    return {
        anyUpcoming,
        formsLength,
        isPatientLogin
    }
});

export const selectOverviewHeader = createSelector(selectBookingRequestState, selectPatientName, selectIsPatientLogin, selectIsRTL, selectOverviewFormsLength, selectIsOnlyAnonymousForms, (bookingRequestState, patientName, isPatientLogin, isRtl, formsLength, onlyAnonymousForms )  => {
    return {
        bookingRequestState,
        patientName,
        isPatientLogin,
        isRtl,
        formsLength,
        onlyAnonymousForms
    }
});

export const selectFormOverview = createSelector(selectBookingRequestState, selectNextForm, selectIsTestEnvironment, selectCurrentLanguageCode, selectOverviewForms, selectBookingRequestId, (bookingRequestState, nextForm, isTest, currentLanguageCode, formsList, bookingRequestId) => {
    return {
        bookingRequestState,
        nextForm,
        isTest,
        currentLanguageCode,
        formsList,
        bookingRequestId
    }
});