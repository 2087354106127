import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RenderType } from "Shared/app/enums/renderType";
import { Store } from "@ngrx/store";
import * as PatientActions from "../state/patient.actions";
import { PatientState } from "../models/patientState";

@Injectable({
    providedIn: 'root',
})

export class FormRoutingService {
    private migratedFormList = [];

    constructor(
        private router: Router,
        private store: Store<PatientState>
    ) { }

    public performForm(renderType: RenderType, formId: string, bookingId: string, bookedFormId: string){
        this.store.dispatch(PatientActions.setActiveForm({id: bookedFormId}));
        if(renderType === RenderType.Static) {
            if (this.migratedFormList.includes(formId)){
                this.router.navigateByUrl(`session/form/${bookedFormId}/static`);
            } else {
                this.router.navigateByUrl(`session/form/${bookedFormId}/mapped`);
            }
        } else {
            this.router.navigateByUrl(`session/form/${bookedFormId}/dynamic`);
        }
    }
}