import { Component } from "@angular/core";

@Component({
    selector: 'wfs-logo',
    templateUrl: './wf-logo.component.html',
    styleUrls: ['./wf-logo.component.scss'],
    standalone: true
})

export class WFSLogoComponent {

}