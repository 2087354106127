import { Injectable } from "@angular/core";
import { ShortLanguage, FullLanguage } from "../../models/language";

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    languages: FullLanguage[] = [
        {
            name: 'Svenska/Swedish',
            code: 1053,
            resource: 'sv-SE',
            country: 'Svenska',
            rtl: false,
            careproviderName: 'Svenska'
        },
        {
            name: 'English/Engelska',
            code: 1033,
            resource: 'en-US',
            country: 'Engelska',
            rtl: false,
            careproviderName: 'Engelska'
        },
        {
            name: 'Suomeksi/Finska',
            code: 1035,
            resource: 'fi-FI',
            country: 'Finska',
            rtl: false,
            careproviderName: 'Finska'
        },
        {
            name: 'Soomaali/Somaliska',
            code: 1143,
            resource: 'so',
            country: 'Somaliska',
            rtl: false,
            careproviderName: 'Somaliska'
        },
        {
            name: 'Pусский/Ryska',
            code: 1049,
            resource: 'ru',
            country: 'Ryska',
            rtl: false,
            careproviderName: 'Ryska'
        },
        {
            name: 'لعربية/Arabiska',
            code: 1025,
            resource: 'ar',
            country: 'Arabiska',
            rtl: true,
            careproviderName: 'Arabiska'
        },
        {
            name: 'Español/Spanska',
            code: 1034,
            resource: 'es',
            country: 'Spanska',
            rtl: false,
            careproviderName: 'Spanska'
        },
        {
            name: 'ትግርኛ/Tigrinska',
            code: 1139,
            resource: 'ti',
            country: 'Tigrinska',
            rtl: false,
            careproviderName: 'Tigrinska'
        },
        {
            name: 'Soranî/Sydkurdiska',
            code: 146,
            resource: 'ku',
            country: 'Sydkurdiska',
            rtl: true,
            careproviderName: "Sydkurdiska"
        },
        {
            name: 'فارسی/Persiska',
            code: 1065,
            resource: 'fa',
            country: 'Persiska',
            rtl: true,
            careproviderName: "Persiska"
        },
        {
            name: 'Türkçe/Turkiska',
            code: 1055,
            resource: 'tr',
            country: 'Turkiska',
            rtl: false,
            careproviderName: "Turkiska"
        }
    ];

    getLanguageNames(languageCodes: number[]): ShortLanguage[] {
        const formLanguages = [];
        languageCodes.forEach(languageCode => {
            const language = this.languages.find(l => l.code === languageCode);
            formLanguages.push({ code: language?.code, text: language?.careproviderName });
        });

        return formLanguages;
    }

    getLanguageName(languageCode: number): ShortLanguage {
        const language = this.languages.find(l => l.code === languageCode);
        return { code: language.code, text: language.careproviderName };
    }

    getLanguageObject(languageCode: number): FullLanguage {
        const language = this.languages.find(x => x.code === languageCode);
        return language;
    }

    getLanguageObjectByResource(languageResource: string) {
        const language = this.languages.find(x => x.resource === languageResource);
        return language;
    }
}