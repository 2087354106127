import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BookingRequest } from "../models/bookingRequest";
import { BookedForm } from "projects/careprovider/src/app/shared/models/bookedForm";

@Injectable({
    providedIn: 'root',
})

export class BookingRequestService {

    constructor(private http: HttpClient) { }

    getBookingRequests(bookingRequestId: string): Observable<BookingRequest> {
        return this.http.get<BookingRequest>(`api/Engine/BookingRequests/${bookingRequestId}`);
    }

    getFormStateFromServer(bookedFormId: string): Observable<BookedForm> {
        return this.http.get<BookedForm>(`api/Engine/Forms/GetFormState/${bookedFormId}`);
    }
}