import { Inject, Injectable } from "@angular/core";
import * as PatientActions from '../state/patient.actions';
import * as DynamicFormPagerActions from 'Shared/app/forms/actions/form-pager.actions';
import * as DynamicFormActions from 'Shared/app/forms/actions/render-form-actions';
import { ActivatedRoute, Router } from "@angular/router";
import { EMPTY, of } from "rxjs";
import { Store } from "@ngrx/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { selectCurrentLanguageCode, selectLogoutData, selectSendBackToInera } from "./patient.selectors";
import { AuthenticationService } from "../services/authentication.service";
import { BookingRequestService } from "../services/bookingrequest.service";
import { FormRoutingService } from "../services/form-routing.service";
import { PatientLanguageService } from "../services/patient-language.service";
import { LanguageService } from "Shared/app/core/services/language.service";
import { PatientState } from "../models/patientState";
import { WINDOW } from "Shared/app/core/services/window.service";
import { Location } from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class PatientEffects {

    constructor(
        private actions$: Actions,
        private router: Router,
        private aRoute: ActivatedRoute,
        private store: Store<PatientState>,
        private authenticationService: AuthenticationService,
        private bookingRequestService: BookingRequestService,
        private formRoutingService: FormRoutingService,
        private patientLanguageService: PatientLanguageService,
        private languageService: LanguageService,
        @Inject(WINDOW) private WINDOW: Window,
    ) { }

    closeDynamicForm$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DynamicFormPagerActions.closeEndPage,
                DynamicFormActions.SaveDraftFailure,
                DynamicFormActions.SaveResultFailure
            ),
            tap(() => {
                this.router.navigateByUrl("/session");
            })
        )
    }, { dispatch: false });

    dynamicFormLoadQuestionnaireFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DynamicFormActions.loadQuestionnaireFailure),
            tap(() => {
                this.router.navigateByUrl("/session");
            })
        );

    }, { dispatch: false })

    dynamicFormDraft$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DynamicFormActions.SaveDraftComplete),
            map(() => {
                return PatientActions.setActiveFormDraft();
            })
        );
    });

    dynamicFormSave$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DynamicFormActions.SaveResultsComplete),
            map(action => {
                if (action.result.hasEndPage) {
                    this.router.navigateByUrl("/session");
                }
                return PatientActions.setActiveFormCompleted();
            })
        );
    });

    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PatientActions.login),
            switchMap(action => this.authenticationService.login(action.credentials).pipe(
                map((session) => {
                    return PatientActions.loginSuccess({ session: session });
                }),
                catchError(() => {
                    return of(PatientActions.loginFailure());
                })
            ))
        )
    })

    loginSSO$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PatientActions.loginSSO,),
            switchMap(() => this.authenticationService.loadSessionFromServer().pipe(
                map((session) => {
                    return PatientActions.loginSuccess({ session: session });
                }),
                catchError(() => {
                    return of(PatientActions.loginFailure());
                })
            ))
        )
    })

    loadSessionFromServer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PatientActions.loadSessionFromServer,),
            switchMap(() => this.authenticationService.loadSessionFromServer().pipe(
                map((session) => {
                    const exceptedRoutes = ['/accessibilitystatement', '/logoutidp', '/login?loginType=sso'];
                    if (!exceptedRoutes.includes(this.aRoute.snapshot['_routerState'].url)) {
                        this.router.navigateByUrl("session");
                    }
                    if (session.languageId) {
                        const language = this.languageService.getLanguageObject(session.languageId);
                        this.store.dispatch(PatientActions.setCurrentLanguage({ language: language.code, hasSession: true }));
                    }
                    return PatientActions.loadSessionSuccess({ session: session });
                }),
                catchError(() => {
                    this.router.navigateByUrl('login');
                    return of(PatientActions.loadSessionFailure());
                })
            ))
        )
    })

    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PatientActions.logout),
            switchMap(() => this.authenticationService.logout().pipe(
                map(() => {
                    this.router.navigateByUrl('login');
                    return PatientActions.logoutSuccess();
                }),
                catchError(() => {
                    this.router.navigateByUrl('login');
                    return of(PatientActions.logoutFailure());
                })
            ))
        );
    })

    logoutSso$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PatientActions.logoutSso),
            switchMap(() => this.authenticationService.logoutSSO().pipe(
                map(signOutData => {
                    this.WINDOW.location.href = signOutData.url;
                    return PatientActions.logoutSuccess();
                }),
                catchError(() => {
                    this.router.navigateByUrl('login');
                    return of(PatientActions.logoutFailure());
                })
            ))
        );
    })

    checkReturnTo1177$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PatientActions.checkReturnTo1177),
            withLatestFrom(this.store.select(selectSendBackToInera)),
            switchMap(([action, data]) => {
                if (data.shouldReturn) {
                    return of(PatientActions.returnToExternalSite({ url: data.returnUrl }));
                } else {
                    return EMPTY;
                }
            })
        )
    })

    returnToExternalSite$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PatientActions.returnToExternalSite),
            switchMap((action) => this.authenticationService.logout().pipe(
                map( () => {
                    setTimeout( () => {
                        this.WINDOW.location.href = action.url;
                    }, 50);
                    return PatientActions.logoutSuccess();
                }),
                catchError( () => {
                    setTimeout( () => {
                        this.WINDOW.location.href = action.url;
                    }, 50);
                    return of(PatientActions.logoutFailure());
                })
            ))
        );
    })

    setAntiforgery$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                PatientActions.setAntiforgery,
                PatientActions.loginSuccess,
                PatientActions.logoutSuccess,
                PatientActions.logoutFailure
            ),
            switchMap((action) => this.authenticationService.setAntiforgery().pipe(
                switchMap(() => {
                    if (action.type === PatientActions.loginSuccess.type) {
                        return [
                            PatientActions.setSessionLanguage(),
                            PatientActions.antiForgerySet()];
                    }
                    return [PatientActions.antiForgerySet()];
                }),
                catchError(() => {
                    return of(PatientActions.antiForgerySet()); //We'll go along with this? =P
                })
            ))
        );
    })

    loadBookingRequestOnLogin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                PatientActions.loginSuccess
            ),
            switchMap(action => this.bookingRequestService.getBookingRequests(action.session.bookingRequestId).pipe(
                map(bookingRequest => {
                    if (bookingRequest.forms?.length === 1 && bookingRequest.forms[0].formType === 4 && bookingRequest.forms[0].status === 10) {
                        const form = bookingRequest.forms[0];
                        this.formRoutingService.performForm(form.renderType, form.formId, form.bookingId, form.id);
                    } else {
                        this.router.navigateByUrl("session");
                    }
                    return PatientActions.loadBookingRequestSuccess({ bookingRequest: bookingRequest });
                }),
                catchError(() => {
                    return of(PatientActions.loadBookingRequestFailure());
                })
            ))
        );
    })

    loadBookingRequestOnReload$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                PatientActions.loadSessionSuccess,
            ),
            switchMap(action => this.bookingRequestService.getBookingRequests(action.session.bookingRequestId).pipe(
                map(bookingRequest => {
                    return PatientActions.loadBookingRequestSuccess({ bookingRequest: bookingRequest });
                }),
                catchError(() => {
                    return of(PatientActions.loadBookingRequestFailure());
                })
            ))
        );
    })

    loadBookingRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                PatientActions.loadBookingRequest,
            ),
            switchMap(action => this.bookingRequestService.getBookingRequests(action.bookingRequestId).pipe(
                map(bookingRequest => {
                    return PatientActions.loadBookingRequestSuccess({ bookingRequest: bookingRequest });
                }),
                catchError(() => {
                    return of(PatientActions.loadBookingRequestFailure());
                })
            ))
        );
    })

    loadSupportedLanguages$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                PatientActions.loadSupportedLanguagesFromServer
            ),
            switchMap(() => this.patientLanguageService.getSupportedLanguages().pipe(
                map(languages => {
                    return PatientActions.loadSupportedLanguagesSuccess({ supportedLanguages: languages })
                }),
                catchError(() => {
                    return of(PatientActions.loadSupportedLanguagesFailure());
                })
            ))
        );
    })

    setCurrentLanguage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                PatientActions.setCurrentLanguage
            ),
            switchMap((action) => {
                if (action.hasSession) {
                    return of(PatientActions.saveCurrentLanguage({ language: action.language }));
                } else {
                    return of(PatientActions.setSessionLanguageFailure());
                }
            })
        )
    })

    saveCurrentLanguage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                PatientActions.saveCurrentLanguage
            ),
            switchMap(action => this.authenticationService.setSessionLanguage(action.language).pipe(
                map(session => {
                    return PatientActions.setSession({ session: session })
                }, catchError(() => {
                    return of(PatientActions.setSessionLanguageFailure());
                }))
            ))
        )
    })

    setSessionLanguageOnLogin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                PatientActions.setSessionLanguage
            ),
            withLatestFrom(this.store.select(selectCurrentLanguageCode)),
            switchMap(([action, currentLanguage]) => {
                return of(PatientActions.setCurrentLanguage({ language: currentLanguage, hasSession: true }));
            })
        )
    })
}