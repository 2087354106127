import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PatientSession } from "../models/patientSession";
import { Credentials } from "../models/credentials";
import { SignOutData } from "../models/signOutData";

@Injectable({
    providedIn: 'root',
})

export class AuthenticationService {

    constructor(private http: HttpClient) { }

    login(credentials: Credentials) {
        return this.http.post<PatientSession>(`app/Authenticate/Credentials`, credentials);
    }

    loadSessionFromServer(): Observable<PatientSession> {
        return this.http.get<PatientSession>(`app/Authenticate/Session`);
    }

    setSessionLanguage(languageCode: number): Observable<PatientSession> {
        return this.http.post<PatientSession>(`app/Authenticate/Session/${languageCode}`, languageCode);
    }

    setAntiforgery(): Observable<void> {
        return this.http.get<void>('/app/Util/Security/AntiForgery');
    }

    logout() {
        return this.http.post<any>(`app/Authenticate/SignOutWf`, {});
    }

    logoutSSO() {
        return this.http.post<SignOutData>(`app/Authenticate/SignOut`, {});
    }
}