export enum BookingOverviewState {
    Upcoming = "Upcoming", 
    NotStarted = "NotStarted",
    Draft = "Draft",
    InProgress = "InProgress",
    InProgressDraft = "InProgressDraft" ,
    TemporaryCompleted = "TemporaryCompleted",
    Completed = "Completed",
    CompletedAnonymous = "CompletedAnonymous",
    Cancelled = "Cancelled",
    Unknown = "Unknown"
}