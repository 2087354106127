import { createAction, props } from "@ngrx/store";
import { PatientLanguage } from "Shared/app/models/language";
import { PatientSession } from "../models/patientSession";
import { BookingRequest } from "../models/bookingRequest";
import { Credentials } from "../models/credentials";

export const loadBookingRequest = createAction("[Patient] Load Booking Request", props<{ bookingRequestId: string }>());
export const loadBookingRequestSuccess = createAction("[Patient] Load Booking Request Success", props<{ bookingRequest: BookingRequest }>());
export const loadBookingRequestFailure = createAction("[Patient] Load Booking Request Failed");

export const login = createAction("[Patient] Log In", props<{ credentials: Credentials }>());
export const loginSSO = createAction("[Patient] SSO Log In");
export const loginFailure = createAction("[Patient] Login Failed");
export const loginSuccess = createAction("[Patient] Login Success", props<{ session: PatientSession }>());
export const setSession = createAction("[Patient] Set Session", props<{ session: PatientSession }>());

export const loadSessionFromServer = createAction("[Patient] Load Session from Server");
export const loadSessionSuccess = createAction("[Patient] Load Session from Server Success", props<{ session: PatientSession }>());
export const loadSessionFailure = createAction("[Patient] Load Session from Server Failure");

export const logoutIdp = createAction("[Patient] Log out Idp")
export const logout = createAction("[Patient] Log out");
export const logoutSso = createAction("[Patient] Log out SSO");
export const logoutSuccess = createAction("[Patient] Log out Success");
export const logoutFailure = createAction("[Patient] Log out Failure");
export const returnToExternalSite = createAction("[Patient] Return To External Site", props<{ url: string }>());
export const checkReturnTo1177 = createAction("[Patient] Should return to 1177?");

export const setAntiforgery = createAction("[Patient] Set Antiforgery");
export const antiForgerySet = createAction("[Patient] Antiforgery Set");

export const setActiveForm = createAction("[Patient] Set Active Form", props<{ id: string }>());
export const clearActiveForm = createAction("[Patient] Clear Active Form");

export const setActiveFormDraft = createAction("[Patient] Set Active Form Draft");
export const setActiveFormCompleted = createAction("[Patient] Set Active Form Completed");

export const loadSupportedLanguagesFromServer = createAction("[Patient] Load Supported Languages");
export const loadSupportedLanguagesSuccess = createAction("[Patient] Load Supported Languages Success", props<{ supportedLanguages: PatientLanguage[] }>());
export const loadSupportedLanguagesFailure = createAction("[Patient] Load Supported Languages Failure");

export const setCurrentLanguage = createAction("[Patient] Set Current Language", props<{ language: number, hasSession: boolean }>());
export const setSessionLanguage = createAction("[Patient] Set Session Language");
export const setSessionLanguageFailure = createAction("[Patient] Set SessionLanguageFailure");
export const saveCurrentLanguage = createAction("[Patient] Save Current Language", props<{ language: number, }>());

export const updateJsLanguage = createAction("[Patient] Update AngularJs Language", props<{ languageResource: string, isRtl: boolean }>());
