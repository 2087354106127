import { Component } from "@angular/core";
import { notTestedForms, testedForms, approvedForms } from "./data/form-data"

@Component({
    selector: "wfs-accessibility-statement",
    templateUrl: "./accessibility-statement.component.html",
    styleUrls: ['./accessibility-statement.component.scss']
})
export class AccessibilityStatementComponent{
  
    notTestedForms: any;
    testedForms: any;
    approvedForms: any;

    constructor(){
        this.notTestedForms = notTestedForms;
        this.testedForms = testedForms;
        this.approvedForms = approvedForms;
    }
}
