import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';



@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot([],{
        runtimeChecks: {
            strictStateImmutability: false,
            strictActionImmutability: false
        }
    }),
    EffectsModule.forRoot([]),
  ],
  exports: [
    StoreModule,
    EffectsModule
  ]
})
export class RootStateModule { }
