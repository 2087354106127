import { HttpLoaderFactory, SharedModule } from './shared/shared.module';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule, HttpClient } from '@angular/common/http';
import { UpgradeModule } from '@angular/upgrade/static';
import { Router } from '@angular/router';
import { LocationUpgradeModule } from '@angular/common/upgrade';
import { AppRoutingModule } from './app-routing.module';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';

import { AppComponent } from './app.component';
import { LogoutComponent } from './logout/logout.component';
import { CacheInterceptor } from '../../../../Shared/app/forms/interceptors/cache.interceptor';
import { FooterComponent } from './footer/footer.component';
import { AccessibilityStatementComponent } from '../../../../Shared/app/forms/accessibility-statement/accessibility-statement.component';
import { TrustPipe } from '../../../../Shared/app/forms/pipes/trust.pipe';

import { RaygunErrorHandler } from '../../../../Shared/app/core/app.raygun.setup';
import { WINDOW_PROVIDERS } from '../../../../Shared/app/core/services/window.service';
import { RootStateModule } from '../../../../Shared/app/root-state/root-state.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { patientReducer } from './state/patient.reducer'
import { environment } from 'environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { PatientEffects } from './state/patient.effects';

@NgModule({
    imports: [
        RootStateModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientXsrfModule,
        UpgradeModule,
        LocationUpgradeModule.config({ useHash: false }),
        AppRoutingModule,
        SharedModule,
        ModalModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        StoreModule.forFeature('patient', patientReducer),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        }),
        EffectsModule.forFeature([PatientEffects]),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        BsModalRef,
        { provide: ErrorHandler, useClass: RaygunErrorHandler },
        WINDOW_PROVIDERS
    ],
    declarations: [AppComponent, FooterComponent, AccessibilityStatementComponent, LogoutComponent, TrustPipe]
})
export class AppModule {
    constructor(private router: Router) { }

    ngDoBootstrap() {
        this.router.initialNavigation();
    }
}
