import { downgradeInjectable, downgradeComponent } from '@angular/upgrade/static';
import { AppComponent } from './app/app.component';
import { $locationShim } from '@angular/common/upgrade';
import * as d3InterpolatePath from 'd3-interpolate-path';

declare const angular: angular.IAngularStatic;

export function downgradeItems() {
    angular.module('wfApp')
        .directive('wfpApp', downgradeComponent({ component: AppComponent }) as angular.IDirectiveFactory)
        .factory('$location', downgradeInjectable($locationShim))
        .constant('d3InterpolatePath', d3InterpolatePath);
}
