import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as PatientActions from '../state/patient.actions';
import { PatientState } from '../models/patientState';
import { selectLogoutData } from '../state/patient.selectors';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { WINDOW } from 'Shared/app/core/services/window.service';


@Component({
    selector: 'wfp-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
}) 
export class LogoutComponent implements OnInit, OnDestroy {
    private _onDestroy: Subject<void> = new Subject();
    
    private redirectUrl: string;

    constructor(
        private store: Store<PatientState>,
        private router: Router,
        private authenticationService: AuthenticationService,
        @Inject(WINDOW) private WINDOW: Window,
    ) { }
    

    ngOnInit() {
        this.store.select(selectLogoutData).pipe(takeUntil(this._onDestroy)).subscribe( logoutData => {
            if (!logoutData.isLoggedIn){
                this.router.navigateByUrl('/login');
                return;
            }
            if (logoutData.isSsoLogin){
                this.authenticationService.logoutSSO().subscribe( signOutData => {
                    this.store.dispatch(PatientActions.logoutSuccess());
                    setTimeout( () => {
                        this.WINDOW.location.href = signOutData.url;
                    }, 50);
                }, () => {
                    this.store.dispatch(PatientActions.logoutFailure());
                });
            } else {
                this.store.dispatch(PatientActions.logout());
            }
        });
    }
    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }
}