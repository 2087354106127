import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AccessibilityStatementComponent } from '../../../../Shared/app/forms/accessibility-statement/accessibility-statement.component';
import { LogoutComponent } from "./logout/logout.component";

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'login'},
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'session', loadChildren: () => import('./session/session.module').then(m => m.SessionModule)},
    { path: 'accessibilitystatement', component: AccessibilityStatementComponent },
    { path: 'logoutidp', component: LogoutComponent },
    { path: '**', redirectTo: 'login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
    ]
})
export class AppRoutingModule { }
