import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientPaginationPipe } from './patient-pagination.pipe';

import { FormHandlerProvider } from '../upgrades/sevices/formHandler.service.wrapper';
import { HeaderComponent } from './header/header.component';
import { LogoComponent } from './header/logo/logo.component';

import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UibModalStackProvider } from '../upgrades/sevices/uibModalStack.service.wrapper';
import { LoaderComponent } from './loader/loader.component';
import { WFSLogoComponent } from 'Shared/app/stand-alone-components/wfs-logo/wf-logo.component';
import { LanguagePickerComponent } from './language-picker/language-picker.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.contentUrl + '/wf/patient/resources/locale/', '.json');
}

@NgModule({
    declarations: [
        HeaderComponent,
        LogoComponent,
        LanguagePickerComponent,
        PatientPaginationPipe,
        LoaderComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        WFSLogoComponent
    ],
    providers: [
        FormHandlerProvider,
        UibModalStackProvider
    ],
    exports: [
        HeaderComponent,
        LogoComponent,
        CommonModule,
        TranslateModule,
        PatientPaginationPipe,
        LanguagePickerComponent,
        LoaderComponent]
})
export class SharedModule { }
