import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PatientLanguage } from "Shared/app/models/language";

@Injectable({
    providedIn: 'root',
})

export class PatientLanguageService {

    constructor(private http: HttpClient) { }

    getSupportedLanguages(): Observable<PatientLanguage[]> {
        return this.http.get<PatientLanguage[]>(`app/Util/Lang`);
    }
}